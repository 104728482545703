import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Grid from '../components/grid'

import styled from "styled-components"
import tw from "tailwind.macro"

const Section = styled.section`
  ${tw`max-w-md mx-auto`};
`

const Articles = styled.section`
  ${tw`flex flex-col max-w-md`};
`


class RootIndex extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const posts = get(this, 'props.data.allContentfulBlogPost.edges')
        const [author] = get(this, 'props.data.allContentfulPerson.edges')

        return (
            <Layout location={this.props.location} >
                <Helmet title={`${siteTitle} | Web Developer in Stockholm`}>
                    <meta name="description" content="Ben Counsell is a web developer based in Stockholm, Sweden. He writes about Javascript, CSS, Vue.js, React, and everything web." />
                </Helmet>
                {/* <Hero data={author.node} /> */}
                <Articles>
                    {posts.map(({ node }) => {
                        return (
                            <ArticlePreview article={node} />
                        )
                    })}
                </Articles>
            </Layout>
        )
    }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
             ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
